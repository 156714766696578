<template>
	<div class="container">
		<div class="left-menu" :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }">
			<left-list :leftIndex="'6'" :isCollapse="isCollapse"></left-list>
		</div>
		<div class="right-content" :style="{ width: !isCollapse ? '88%' : '95.5%' }">
			<div class="top-nav">
				<top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
			</div>
			<div class="left-list">
				<div class="left-list-title">
					<span>产品在线监测</span>
				</div>
				<div class="left-list-item left-list-active" @click="leftClick('0')">
					<span>传感器列表</span>
				</div>
				<div class="left-list-item" @click="leftClick('1')">
					<span>设备管理</span>
				</div>
			</div>
			<div class="right-next">
				<div class="title">
					<span>{{title}}</span>
				</div>
				<div class="edit-form">
					<div class="item" >
						<div class="label">传感器编码1：</div>
						<el-input placeholder="传感器编码1" v-model="editForm.code1"></el-input>
					</div>
					<div class="item">
						<div class="label">传感器编码2：</div>
						<el-input placeholder="传感器编码2" v-model="editForm.code2"></el-input>
					</div>
					<div class="item">
						<div class="label">传感器编码3：</div>
						<el-input placeholder="传感器编码3" v-model="editForm.code3"></el-input>
					</div>
					<div class="item">
						<div class="label">传感器编码4：</div>
						<el-input placeholder="传感器编码4" v-model="editForm.code4"></el-input>
					</div>
					<div class="item submit">
						<el-button type="primary" @click="submitUpload">保存添加</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import leftList from "../../components/leftList.vue";
	import topHeader from "../../components/header.vue";
	export default {
		components: {
			leftList,
			topHeader,
		},
		inject: ["reload"],
		data() {
			return {
				title: '修改传感器编码',
				isCollapse: false,
				mineList: [],
				millList: [],
				editForm: {
					code1: '',
					code2: '',
					code3: '',
					code4: ''
				}
			};
		},
		created() {
			this.getSensorinfo();
		},
		methods: {
			getSensorinfo(){
				this.$get('sensor/get_details',{
					id:this.$route.query.id
				}).then(res =>{
					// console.log(res);
					this.editForm = res.data.data;
					console.log(this.editForm);
				})
			},
			submitUpload() {
				if (this.editForm.code1 == '' || this.editForm.code2 == '' || this.editForm.code3 == '' || this.editForm.code4 == '') {
					this.$notify.error({
						title: '提示',
						message: '请输入传感器编码！'
					});
					return false;
				}
					this.$post('upd/sensor', {
						id:this.$route.query.id,
						mill_id:this.editForm.mill_id,
						name:this.editForm.name,
						install_position:this.editForm.install_position
					}).then(res => {
						if (res.data.status == 200) {
							this.$notify({
								title: "提示",
								message: "操作成功！",
								type: "success",
							});
							this.$router.push("/sensor");
						} else {
							this.$notify.error({
								title: "提示",
								message: res.data.message,
							});
						}
					})

			},
			getMilllist() {
				this.$get('mill/get_list', {
					page: 1,
					num: 999
				}).then(res => {
					this.millList = res.data.data.data;
				})
			},
			getMinelist() {
				this.$get('mine/get_list', {
					page: 1,
					num: 999
				}).then(res => {
					this.mineList = res.data.data.data;
				})
			},
			leftClick(index) {
				if (index == 0 && this.$route.path != "/sensor") {
					this.$router.push("/sensor");
				} else if(index == 1 && this.$route.path != "/mill"){
					this.$router.push("/mill");
				} else {
					this.reload();
				}
			},
			openMenu() {
				this.isCollapse = !this.isCollapse;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.xg-input {
		width: 300px;
	}

	.edit-form {
		.submit {
			.el-button {
				width: 150px;
				margin-left: 50px;
			}
		}

		.item {
			margin-top: 30px;

			.label {
				display: inline-block;
				vertical-align: middle;
				width: 120px;
			}

			.yield {
				margin-left: 30px;
				font-size: 14px;

				.commodity {
					margin-top: 15px;

					.el-button {
						margin-left: 15px;
					}
				}
			}

			.el-input,
			.el-select {
				width: 230px;
			}
		}
	}

	.right-content {
		height: 100vh;
		overflow-x: hidden;
		overflow-y: scroll;
		vertical-align: top;
		display: inline-block;

		&::-webkit-scrollbar {
			display: none;
		}

		.date-search {
			margin-top: 30px;

			.el-button {
				padding: 12px 55px;
			}

			.el-input,
			.el-select {
				width: 200px;
			}

			.left,
			.right {
				display: inline-block;
			}

			.right {
				width: 1000px;
				vertical-align: top;
			}
		}

		.left-list {
			width: 10%;
			height: 93vh;
			overflow-x: hidden;
			overflow-y: scroll;
			font-size: 14px;
			border-right: 20px solid #f2f2f2;
			display: inline-block;
			text-align: center;

			&::-webkit-scrollbar {
				display: none;
			}

			&-title {
				margin: 20px 0;
				font-size: 16px;
			}

			&-item {
				padding: 15px 0;
				cursor: pointer;
				transition: all 0.2s;

				&:hover {
					color: #fff;
					background-color: #3291f8;
				}
			}

			&-active {
				color: #fff;
				background-color: #3291f8;
			}
		}

		.right-next {
			height: 93vh;
			width: 88.5%;
			overflow-x: hidden;
			overflow-y: scroll;
			border-top: 20px solid #f2f2f2;
			padding: 20px;
			box-sizing: border-box;
			display: inline-block;
			vertical-align: top;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		.title {
			color: #000;
			height: 10px;
			padding-left: 15px;
			line-height: 10px;
			border-left: 5px solid #80c4f8;
			margin-bottom: 10px;
		}

		.top-nav {
			display: flex;
			box-sizing: border-box;
			justify-content: space-between;
			padding: 15px;

			.left-icon {
				i {
					cursor: pointer;
					color: #3291f8;
					font-size: 30px;
				}

				.el-icon-refresh-right {
					margin-left: 30px;
				}
			}

			.right-user {
				font-size: 14px;

				span {
					vertical-align: middle;
					margin-right: 20px;
				}

				.colse-btn {
					cursor: pointer;
					vertical-align: middle;
					border: 1px solid #999;
					display: inline-block;
					padding: 5px 20px;
					border-radius: 50px;

					span {
						margin-right: 10px;
					}

					i {
						vertical-align: middle;
					}
				}
			}
		}
	}

	.left-menu {
		display: inline-block;

		.iconyonghuzu {
			font-size: 18px;
			margin: 0 5px;
		}
	}

	.el-menu {
		width: 100%;
		background-color: #20222a;
		height: 100vh;
	}

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		background-color: #20222a;
		color: #fff;
		height: 100vh;
		width: 100%;
	}

	.el-menu-item:focus,
	.el-menu-item:hover {
		color: #000;
		background-color: #ecf5ff;
	}

	.el-menu-item {
		color: #fff;

		i {
			color: #3291f8;
		}
	}

	.el-menu-item.is-active {
		background-color: #ecf5ff;
		color: #20222a;
	}

	.menu-h5 {
		margin: 20px;
		text-align: center;
		font-size: 20px;
	}

	.menu-icon {
		width: 100%;
		color: #fff;
		box-sizing: border-box;
		padding: 30px 22px;
	}
</style>
